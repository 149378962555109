/* * @Author: liuzhixiang * @Date: 2021-02-06 15:24:45 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-06 15:24:45 */
<template>
  <div class="shoppingCart">
    <div class="width_1200">
      <div class="cont">
        <div class="bread">
          <bread-crumb></bread-crumb>
        </div>
        <div class="bgc_ffffff">
          <div class="padd">
            <el-table
              :data="tableData"
              ref="multipleTable"
              :header-cell-style="{ background: '#F5F6F6', color: '#333333' }"
              :cell-style="{ padding: '24px 0' }"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" label="全选" width="55">
              </el-table-column>
              <el-table-column label="课程信息">
                <template slot-scope="scope">
                  <div class="td_flex">
                    <img v-lazy="scope.row.CoverImg" alt="" />
                    <div class="kcxx">
                      <p class="fs_16">
                        {{ scope.row.GoodsName }}
                      </p>
                      <p>有效期：{{ scope.row.CreateTime }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="180">
                <template slot-scope="scope">
                  <div class="price">
                    <p v-if="scope.row.IsVip == 0">
                      ￥{{ scope.row.picie | priceToFixed }}
                    </p>
                    <p class="c_FFA11A" v-else-if="scope.row.IsVip == 1">
                      <i class="vip_img"></i>
                      VIP会员：￥{{ scope.row.picie | priceToFixed }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="80">
                <template slot-scope="scope">
                  <div>×1</div>
                </template>
              </el-table-column>
              <el-table-column label="小计" width="180">
                <template slot-scope="scope">
                  <div class="price">
                    <p v-if="scope.row.IsVip == 0">
                      ￥{{ scope.row.picie | priceToFixed }}
                    </p>
                    <p class="c_FFA11A" v-else-if="scope.row.IsVip == 1">
                      <i class="vip_img"></i>
                      VIP会员：￥{{ scope.row.picie | priceToFixed }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <div>
                    <span @click="deleteR(scope.row)">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-row v-if="tableData.length > 0">
              <el-col :span="24">
                <el-pagination
                  style="margin: 0 !important"
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="page"
                  :page-size="pageSize"
                  layout="prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="2">
                <el-checkbox
                  v-model="selectAll"
                  :indeterminate="isIndeterminate"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </el-col>
              <el-col :span="2">
                <p><span @click="allDelete()">删除</span></p>
              </el-col>
              <el-col :span="6"
                ><p>已选择{{ multipleSelection.length }}门课程</p></el-col
              >
              <el-col :span="6">
                <p>￥{{ picie | priceToFixed }}</p>
                <!-- <p class="c_FFA11A">
                  <i class="vip_img"></i>VIP会员：￥{{ vipPicie | priceToFixed }}
                </p> -->
              </el-col>
              <el-col :span="8">
                <el-button class="buy" @click="handBuy()">立即购买</el-button>
                <!-- <el-button class="vipBuy">会员购买</el-button> -->
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import { orderQueryCourseCarList, orderDeleteCourseCar } from "@/api/order";
export default {
  data() {
    return {
      levelList: [],
      tableData: [],
      selectAll: false,
      isIndeterminate: false,
      multipleSelection: [],
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
      picie: 0,
      vipPicie: 0,
      ids: [],
    };
  },
  components: {
    BreadCrumb,
  },
  created() {},
  mounted() {
    this.postOrderQueryCourseCarList();
  },
  methods: {
    //全部购买
    handBuy() {
      var userInfo = JSON.parse(this.$store.state.userInfo);
      if (!userInfo.IsIdentificate) {
        this.$confirm('你还没实名认证不能购买课程?', '提示', {
          confirmButtonText: '去实名',
           cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.$router.push("/personal/personalData");
        }).catch(() => {
         
        });
        return false;
      }
      if (this.multipleSelection == 0) {
        this.$message.info("请选择购买");
        return false;
      }
      let obj = [];
      this.multipleSelection.forEach((item, i) => {
        obj[i] = {
          Id: item.Id,
          GoodsId: item.GoodsId,
          IsVip: item.IsVip,
          GoodsCategory: item.GoodsCategory,
        };
      });
      this.$router.push({
        path: "/course/order",
        query: { data: JSON.stringify(obj) },
      });
    },
    //删除全部
    allDelete() {
      if (this.multipleSelection == 0) {
        this.$message.info("请选择删除的列");
        return false;
      }
      this.open();
    },
    //删除列
    deleteR(row) {
      this.ids = [];
      this.ids.push(row.Id + "");
      this.open();
    },
    open() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((action, instance, done) => {
          if (action === "confirm") {
            this.postOrderDeleteCourseCar();
          }
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    // 删除接口
    async postOrderDeleteCourseCar() {
      const res = await orderDeleteCourseCar(this.ids);
      if (res.success === true) {
        this.$message.success(res.msg);
        this.page = 1;
        this.postOrderQueryCourseCarList();
      } else {
        this.$message.error(res.msg);
      }
    },
    //购物车列表
    async postOrderQueryCourseCarList() {
      let parm = {
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await orderQueryCourseCarList(parm);
      if (res.success === true) {
        this.tableData = res.response?.data ? res.response?.data : [];
        this.total = res.response?.dataCount ? res.response?.dataCount : 0;
        this.tableData.forEach((tab) => {
          // IsVip 0 标准课程 1 Vip课程
          if (tab.IsVip == 0) {
            tab.picie = tab.OriginalPrice;
          } else {
            tab.picie = tab.VipPrice;
          }
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postOrderQueryCourseCarList();
    },
    //全选
    handleCheckAllChange(val) {
      if (val == true) {
        this.tableData.forEach((tab) => {
          this.$refs.multipleTable.toggleRowSelection(tab);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //tbale全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.picie = 0;
      this.vipPicie = 0;
      this.ids = [];
      this.multipleSelection.forEach((mult, i) => {
        this.picie = this.picie + mult.picie;
        // this.vipPicie = this.vipPicie + mult.VipPrice;
        this.ids.push(mult.Id + "");
      });
      //独立全选同步
      let checkedCount = val.length;
      this.selectAll = checkedCount === this.tableData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length;
    },
  },
};
</script>

<style lang="less" scoped>
.shoppingCart {
  // margin-top: -30px;
  height: auto;
  background-color: #f5f5f5;
  box-shadow: inset 0px 30px 21px -30px rgba(110, 110, 110, 0.2);
  .cont {
    min-height: calc(100vh - 261px);
    margin-bottom: 30px;
    .padd {
      padding: 30px 30px 0;
      /deep/ .el-table {
        font-size: 14px;
        color: #333333;
        &::before {
          height: 0;
        }
        th,
        tr,
        td {
          text-align: center;
          border-color: #f2f3f3;
        }
        .td_flex {
          display: flex;
          align-items: center;
          img {
            width: 160px;
            height: 90px;
          }
          .kcxx {
            text-align: left;
            margin-left: 20px;
            line-height: 150%;
            p {
              &:nth-child(1) {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              &:nth-child(2) {
                margin-top: 10px;
              }
            }
          }
        }
        .price {
          p {
            &:nth-child(2) {
              margin-top: 5px;
            }
          }
        }
      }
      .el-row {
        font-size: 14px;
        padding: 16px 0;
        .el-col {
          &:nth-child(3),
          &:nth-child(4) {
            text-align: center;
          }
          &:nth-child(4) {
            p {
              &:last-child {
                margin-top: 5px;
              }
            }
          }
          &:last-child {
            text-align: right;
            .el-button {
              width: 120px;
              height: 40px;
              border-radius: 4px;
              font-size: 16px;
              color: #ffffff;
            }
            .buy {
              background-color: #e62929;
            }
            .vipBuy {
              background-color: #ffa11a;
              margin-left: 30px;
            }
          }
          /deep/.el-checkbox {
            .el-checkbox__label {
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>
